import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextLayout from '../UI/TextLayout';

import styles from './pages-css/philosophy.module.css';

import cottonimg from '../assets/images/material/Cotton-560739.jpg';
import feathersimg from '../assets/images/material/Feath_ers-560737.jpg';
import feathersmiximg from '../assets/images/material/Feather-Mix-560735.jpg';
import hemplinenimg from '../assets/images/material/Hemp-Linnen-560732.jpg';
import heveawoodimg from '../assets/images/material/Hevea-Wood-560740.jpg';
import naturallateximg from '../assets/images/material/Natural-Latex-560738.jpg';
import naturalleatherimg from '../assets/images/material/Natural-Leather-560734.jpg';
import pollyfillimg from '../assets/images/material/Poly-Fill-560736.jpg';
import woolfeltimg from '../assets/images/material/Wool-Felt-560733.jpg';

import { Helmet } from 'react-helmet-async';

function Philosophy() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Philosophy</title>
        <meta
          name="description"
          content="Bison Natural Authentic Design - Philosophy"
        ></meta>
        <link rel="canonical" href="/philosophy" />
      </Helmet>
      <Container className={styles['philosophy-text-container']}>
        <TextLayout
          title="BISON PHILOSOPHY"
          description={
            <>
              <p>
                The Bison was chosen as our proud logo and symbol of succesful
                revival of a natural resource, that was nearly extinct. It was
                for hundreds of years the sole source of survial for people.
                After its near extinction, it is now experiencing a very
                promising and successful recovery.
              </p>
              <p>
                In the same way we try to promote a recovery of the use of
                technology and material, which has served humans perfectly in
                the past without any disturbance to our environment.
              </p>
              <p>
                Nature is still our most prominent and superior supplier of raw
                material and we encourage people, to recognize this and
                participate.
              </p>
            </>
          }
          concluding={`"We borrow from nature ... and return after use"`}
        ></TextLayout>
      </Container>
      <Container className={`${styles['philosophy-image-container']} mb-5`}>
        <Row>
          <Col xs={6} md={4}>
            <img src={cottonimg} className="w-100" alt="cotton" />
            <p>Cotton</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={feathersimg} className="w-100" alt="feather" />
            <p>feathers</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={feathersmiximg} className="w-100" alt="feather mix" />
            <p>feathers mix</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={hemplinenimg} className="w-100" alt="hemp line" />
            <p>hemp linen</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={heveawoodimg} className="w-100" alt="hevea wood" />
            <p>hevea wood</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={naturallateximg} className="w-100" alt="natural latex" />
            <p>natural latex</p>
          </Col>
          <Col xs={6} md={4}>
            <img
              src={naturalleatherimg}
              className="w-100"
              alt="natural leather"
            />
            <p>natual leather</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={pollyfillimg} className="w-100" alt="polly fill" />
            <p>polly fill</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={woolfeltimg} className="w-100" alt="wool felt" />
            <p>wool felt</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Philosophy;
