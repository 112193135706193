import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BackToTopButton from '../UI/BackToTopButton';
import { HelmetProvider } from 'react-helmet-async';

function RootLayout() {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Header></Header>
        <main>
          <Outlet />
        </main>
        <Footer></Footer>
        <BackToTopButton></BackToTopButton>
      </HelmetProvider>
    </>
  );
}

export default RootLayout;
