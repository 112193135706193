import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextLayout from '../UI/TextLayout';

import styles from './pages-css/heritage.module.css';

import bisonImage from '../assets/images/Bison-560731.jpg';

import { Helmet } from 'react-helmet-async';

function Heritage() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Heritage</title>
        <meta
          name="description"
          content="Bison Natural Authentic Design - Heritage"
        ></meta>
        <link rel="canonical" href="/heritage" />
      </Helmet>
      <Container fluid className={`${styles.container} p-5`}>
        <Row className={styles.row}>
          <Col sm={12} md={6}>
            <TextLayout
              title="BISON HERITAGE"
              description={
                <>
                  BISON was chosen as our proud logo and a symbol of the revival
                  of a nearly extinct, truly amazing, natural resource. For
                  hundreds of years this massive animal was the sole source of
                  survival for many people and it is now experiencing a huge
                  recovery.
                  <br />
                  <br />
                  Today we promote using the latest technology married with only
                  the best natural raw materials, all of which have enriched
                  human lives without any impact on The Environment. Nature is
                  our most precious resource and we encourage everyone to join
                  with us in recognition of using the renewable product.
                </>
              }
              concluding="... the revival of traditional
                  craftmanship"
            />
          </Col>
          <Col sm={12} md={6} className={styles.image}>
            <img src={bisonImage} alt="bison" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Heritage;
