import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextLayout from '../UI/TextLayout';

import styles from './pages-css/designer.module.css';

import logo2k from '../assets/logo/2K-Logo-561787.png';
import designer from '../assets/images/KLAUS-KUMMER-561785.jpg';

import { Helmet } from 'react-helmet-async';

function Designer() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Designer</title>
        <meta
          name="description"
          content="Bison Natural Authentic - Designer"
        ></meta>
        <link rel="canonical" href="/designer" />
      </Helmet>
      <Container fluid className={styles['designer-container']}>
        <Row>
          <Col xs={12} md={6}>
            <TextLayout
              title="DESIGNER"
              description={
                <>
                  <p>
                    BISON manufactures exquisite furniture collections for
                    people with high demands in design and comfort, but also
                    would like to support sustainability and traditional
                    craftmanship.
                  </p>
                  <p>
                    Bison products and collections are manufactured as far as
                    possible out of high-quality, selected natural raw materials
                    and ingredients. The Bison Philosophy and collections have
                    been conceived, crafted and developed by Klaus J. Kummer, 2K
                    DESIGN STUDIO.
                  </p>
                  <p>Klaus J. Kummer Concept:</p>
                  <p>
                    DESIGNER BISON manufactures exquisite furniture collections
                    for people with high demands in design and comfort, but also
                    would like to support sustainability and traditional
                    craftmanship. Bison products and collections are
                    manufactured as far as possible out of high-quality,
                    selected natural raw materials and ingredients. The Bison
                    Philosophy and collections have been conceived, crafted and
                    developed by Klaus J. Kummer, 2K DESIGN STUDIO. Klaus J.
                    Kummer Concept: In my designs I try to maintain the
                    es­thet­ical values, rational functionality and ergonomics
                    of the great Scan­dinavian design tradition - in a time­less
                    design, form and ma­te­r­ial ­lan­guage, that is as far as
                    possible stripped of­f su­per­ficial dec­o­ra­tive elements
                    and seeks the reduction to the functional core.
                  </p>
                  <p>2K Motto:</p>
                  <p>
                    "Perfection is achieved not, when noth­ing ­can be added
                    anymore, but when noth­ing can be taken away any more”
                    (Saint Ex­upéry)
                  </p>
                  <img src={logo2k} className="w-25" alt="2k logo" />
                </>
              }
              concluding="KLAUS KUMMER, GERMAN ARCHITEKT, DESIGNER & ARTISAN"
            ></TextLayout>
          </Col>
          <Col xs={12} md={6}>
            <img src={designer} className="w-100" alt="2k designer" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Designer;
