import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextLayout from '../UI/TextLayout';

import styles from './pages-css/design.module.css';
import design from '../assets/images/bison-natural-authentik-design-559030.jpg';

import { Helmet } from 'react-helmet-async';

function Design() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Design</title>
        <meta
          name="description"
          content="Bison Natural Authentic - Design"
        ></meta>
        <link rel="canonical" href="/design" />
      </Helmet>
      <Container fluid className={`${styles.container} p-5`}>
        <Row className={styles.row}>
          <Col sm={12} md={6}>
            <TextLayout
              title="Design"
              description={
                <>
                  Bison impresses by its solid design and perfect comfort. A
                  rational minimalistic form language and functional added value
                  merge into harmonic objects. By omitting all superfluous
                  decorative elements, Bison product are reduced to the essence.
                  <br />
                  <br />
                  A neutral style, which can be merged with any architecture or
                  surrounding space.
                  <br />
                  <br />
                  Bison represents the ultimate and uncompromising realization
                  of a material philosophy, that result in some of the highest
                  value added and natural products in the market.
                </>
              }
              concluding='"Aesthetic Functionality"'
            />
          </Col>
          <Col sm={12} md={6} className={styles.image}>
            <img src={design} alt="design" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Design;
