import styles from './UI-css/TextLayout.module.css';

function TextLayout(props) {
  return (
    <div className={`${styles['text-container']} ${props.className}`}>
      <h1 className={styles.title}>{props.title}</h1>
      <span className={styles.description}>{props.description}</span>
      <br />
      <span className={styles.concluding}>{props.concluding}</span>
    </div>
  );
}

export default TextLayout;
