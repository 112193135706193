import UIButton from '../UI/UIButton';
import styles from './components-css/navigationBar.module.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavLink } from 'react-router-dom';

import logo from '../assets/logo/bison-natural-logo2-561791.png';

function NavigationBar() {
  return (
    <>
      <div className={styles.navigationBar}>
        <Navbar collapseOnSelect expand="lg">
          <Container fluid className="mx-5">
            <Navbar.Brand href="/">
              <img src={logo} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <UIButton>
                  <NavLink
                    to="/"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    Home
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="heritage"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    Heritage
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="design"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    design
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="philosophy"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    philosophy
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="leather"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    leather
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="designer"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    designer
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="models"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    models
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="gallery"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    gallery
                  </NavLink>
                </UIButton>
                <UIButton>
                  <NavLink
                    to="contact-us"
                    className={({ isActive }) => {
                      return isActive ? styles.active : '';
                    }}
                  >
                    contact us
                  </NavLink>
                </UIButton>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default NavigationBar;
