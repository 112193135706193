import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FormLabel } from 'react-bootstrap';

import { Helmet } from 'react-helmet-async';

import styles from './pages-css/contact.module.css';

function Contact() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Contact</title>
        <meta
          name="description"
          content="Bison Natural Authentic - Contact"
        ></meta>
        <link rel="canonical" href="/contact-us" />
      </Helmet>
      <Container className={`${styles['contact-container']} py-5`}>
        <Row>
          <Col sm={12} md={6}>
            <Container className={styles['contact-info-container']}>
              <p>
                Please fill in the form with your questions or comments and we
                will get back to you as soon as possible and normally within 24
                hours during working days.
              </p>
              <h1>CONTACT US</h1>
              <Row className={styles['contact-info-grid']}>
                <Col>
                  <h2>Matthew Slater</h2>
                  <ul>
                    <li>
                      <i className="fa-solid fa-phone"></i>
                      <a href="tel:+66866881035">+66 86 688 1035</a>
                    </li>
                    <li>
                      <i className="fa-regular fa-envelope"></i>
                      <a href="mailto:matt@theworks.co.th">matt@asalon.co.th</a>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <h2>Klaus Kummer</h2>
                  <ul>
                    <li>
                      <i className="fa-solid fa-phone"></i>
                      <a href="tel:+66818261624">+66 81 826 1624</a>
                    </li>
                    <li>
                      <i className="fa-regular fa-envelope"></i>
                      <a href="mailto:matt@theworks.co.th">
                        klausk@kdt-inter.com
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <h2>Showroom Locations</h2>
                  <p>
                    147 Soi Sukhumvit 63, Khlong Tan Nuea, Watthana, Bangkok
                    10110
                  </p>
                  <a
                    href="https://goo.gl/maps/WE96diYuaz8YoHsH6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on map
                  </a>
                  <Container className="p-0 mt-3">
                    <iframe
                      title="embedded-google-map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.777652821488!2d100.5841253!3d13.731907200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29fad2e776ed1%3A0x6d2f52ab96edf0c2!2sBurgundy%20Place%20Thonglor!5e0!3m2!1sen!2s!4v1685690131890!5m2!1sen!2s"
                      width="auto"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col sm={12} md={6}>
            {/* <Container className={styles['form-container']}>
              <Form
                action="mailto:mrhunty3103@gmail.com"
                method="POST"
                className="w-75"
              >
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your name"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@acb.com"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0123456789"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Your message goes here"
                  ></Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button>
              </Form>
            </Container> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
