import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import RootLayout from './pages/RootLayout';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Heritage from './pages/Heritage';
import Design from './pages/Design';
import Home from './pages/Home';
import Philosophy from './pages/Philosophy';
import Leather from './pages/Leather';
import Designer from './pages/Designer';
import Models from './pages/Models';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import ErrorPage from './pages/Error404';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: 'true',
          element: <Home />,
        },
        {
          path: '/heritage',
          element: <Heritage />,
        },

        {
          path: '/design',
          element: <Design />,
        },
        {
          path: '/philosophy',
          element: <Philosophy />,
        },
        {
          path: '/leather',
          element: <Leather />,
        },
        {
          path: '/designer',
          element: <Designer />,
        },
        {
          path: '/models',
          element: <Models />,
        },
        {
          path: '/gallery',
          element: <Gallery />,
        },
        {
          path: '/contact-us',
          element: <Contact />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
