import Button from 'react-bootstrap/Button';
// import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './UI-css/UIButton.module.css';

function UIButton(props) {
  return (
    <>
      <Button
        variant="link"
        className={`${styles.UIButton} ${props.className}`}
        id={props.id}
      >
        {props.children}
      </Button>
    </>
  );
}

export default UIButton;
