import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import BackToTopButton from '../UI/BackToTopButton';
import styles from './pages-css/error404.module.css';

function ErrorPage() {
  return (
    <>
      <Header></Header>
      <main>
        <div className={`mx-auto my-5 container ${styles['error-container']}`}>
          <p>PAGE NOT FOUND</p>
          <Link to={'/'}>Click here to go back to home page</Link>
        </div>
      </main>
      <Footer></Footer>
      <BackToTopButton></BackToTopButton>
    </>
  );
}

export default ErrorPage;
