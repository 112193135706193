import { useEffect } from 'react';
import Viewer from 'viewerjs';
import Container from 'react-bootstrap/Container';
import '../../node_modules/viewerjs/dist/viewer.css';
import styles from './pages-css/gallery.module.css';

import img1 from '../assets/gallery/Bison-Natural-Authentik-Gallery-1-559048.jpg';
import img2 from '../assets/gallery/Bison-Natural-Authentik-Gallery-10-559039.jpg';
import img3 from '../assets/gallery/Bison-Natural-Authentik-Gallery-14-559035.jpg';
import img4 from '../assets/gallery/Bison-Natural-Authentik-Gallery-16-561790.jpg';
import img5 from '../assets/gallery/Bison-Natural-Authentik-Gallery-17-562961.jpg';
import img6 from '../assets/gallery/Bison-Natural-Authentik-Gallery-18-561788.jpg';
import img7 from '../assets/gallery/Bison-Natural-Authentik-Gallery-19-562962.jpg';
import img8 from '../assets/gallery/Bison-Natural-Authentik-Gallery-20-562960.jpg';
import img9 from '../assets/gallery/Bison-Natural-Authentik-Gallery-21-562959.jpg';
import img10 from '../assets/gallery/Bison-Natural-Authentik-Gallery-22-562963.jpg';
import img11 from '../assets/gallery/Bison-Natural-Authentik-Gallery-5-559044.jpg';
import img12 from '../assets/gallery/Bison-Natural-Authentik-Gallery-6-559043.jpg';
import img13 from '../assets/gallery/Bison-Natural-Authentik-Gallery-7-559042.jpg';
import img14 from '../assets/gallery/Bison-Natural-Authentik-Gallery-8-559041.jpg';
import img15 from '../assets/gallery/Bison-Natural-Authentik-Gallery-9-559040.jpg';
import TextLayout from '../UI/TextLayout';

import { Helmet } from 'react-helmet-async';

function Gallery() {
  useEffect(() => {
    const gallery = new Viewer(document.getElementById('images'), {
      viewed() {
        gallery.zoomTo(1);
      },
      toolbar: false,
      title: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Gallery</title>
        <meta
          name="description"
          content="Bison Natural Authentic Design - Gallery"
        ></meta>
        <link rel="canonical" href="/gallery" />
      </Helmet>
      <Container>
        <TextLayout
          title="gallery"
          description="CLICK ON AN IMAGE TO VIEW IT IN FULL SIZE"
          className="mt-5"
        ></TextLayout>
      </Container>
      <Container fluid>
        <div className={`${styles['images-container']} my-5`}>
          <ul className={styles['images-list']} id="images">
            <li className={styles['images-item']}>
              <img src={img1} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img2} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img3} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img4} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img5} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img6} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img7} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img8} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img9} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img10} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img11} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img12} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img13} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img14} alt="" />
            </li>
            <li className={styles['images-item']}>
              <img src={img15} alt="" />
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
}

export default Gallery;
