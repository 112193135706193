import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextLayout from '../UI/TextLayout';

import styles from './pages-css/models.module.css';

import juniorsofa1 from '../assets/images/models/JUNIOR-SOFAS-001-561158.png';
import juniorsofa2 from '../assets/images/models/JUNIOR-SOFAS-0012-561159.png';
import juniorsofa3 from '../assets/images/models/JUNIOR-SOFAS-0013-561160.png';
import standardsofa1 from '../assets/images/models/STANDARD-SOFAS-561155-1.png';
import standardsofa2 from '../assets/images/models/STANDARD-SOFAS002-561157-2.png';
import standardsofa3 from '../assets/images/models/STANDARD-SOFAS001-561156-3.png';
import loungesofa1 from '../assets/images/models/LOUNGE-SOFAS-561152-1.png';
import loungesofa2 from '../assets/images/models/LOUNGE-SOFAS002-561154-2.png';
import loungesofa3 from '../assets/images/models/LOUNGE-SOFAS001-561153-3.png';

import { Helmet } from 'react-helmet-async';

function Models() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Leather</title>
        <meta
          name="description"
          content="Bison Natural Authentic Design - Leather"
        ></meta>
        <link rel="canonical" href="/leather" />
      </Helmet>
      <Container className={styles['models-container']}>
        <Row className={styles['models-parent-container']}>
          <Col>
            <TextLayout
              title="BISON MODELS"
              description={
                <>
                  <p>
                    BISON MODELS - JUNIOR D = 110 CM, STANDARD D = 120 CM,
                    LOUNGE D = 130 CM
                  </p>
                </>
              }
            ></TextLayout>
          </Col>
          <Col>
            <Row className={styles['models-parent-container']}>
              <Col>
                <Row className={styles['models-children-container']}>
                  <Col sm={12} md={3}>
                    <TextLayout
                      title="JUNIOR SOFAS"
                      description={
                        <>
                          <p>SEAT HEIGHT = 41 CM</p>
                          <p>SEAT DEPTH = 55 CM</p>
                          <p>ARM WIDTH = 25 CM</p>
                          <p>ARM HEIGHT = 60 CM</p>
                        </>
                      }
                    ></TextLayout>
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={juniorsofa1} alt="junior sofa overall" />
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={juniorsofa2} alt="junior sofa side" />
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={juniorsofa3} alt="junior sofa front " />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className={styles['models-children-container']}>
                  <Col sm={12} md={3}>
                    <TextLayout
                      title="STANDARD SOFAS"
                      description={
                        <>
                          <p>SEAT HEIGHT = 41 CM</p>
                          <p>SEAT DEPTH = 60 CM</p>
                          <p>ARM WIDTH = 25 CM</p>
                          <p>ARM HEIGHT = 60 CM</p>
                        </>
                      }
                    ></TextLayout>
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={standardsofa1} alt="standard sofa overall" />
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={standardsofa2} alt="standard sofa overall" />
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={standardsofa3} alt="standard sofa overall" />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className={styles['models-children-container']}>
                  <Col sm={12} md={3}>
                    <TextLayout
                      title="LOUNGE SOFAS"
                      description={
                        <>
                          <p>SEAT HEIGHT = 41 CM</p>
                          <p>SEAT DEPTH = 70 CM</p>
                          <p>ARM WIDTH = 30 CM</p>
                          <p>ARM HEIGHT = 60 CM</p>
                        </>
                      }
                    ></TextLayout>
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={loungesofa1} alt="lounge sofa overall" />
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={loungesofa2} alt="lounge sofa overall" />
                  </Col>
                  <Col sm={12} md={3}>
                    <img src={loungesofa3} alt="lounge sofa overall" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Models;
