import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import img1 from '../assets/carousel-images/Bison-Natural-Authentik-sofa-565320.jpg';
import img2 from '../assets/carousel-images/bison-natural-authentik-2-558321.jpg';
import img3 from '../assets/carousel-images/bison-natural-authentik-4-562885.jpg';
import img4 from '../assets/carousel-images/bison-natural-authentik-558320.jpg';
import img5 from '../assets/images/bison-natural-authentik-3-558649.jpg';
import bison from '../assets/images/Bison-560731.jpg';
import comfort from '../assets/images/bison-natural-authentik-558320-comfort.jpeg';
import design from '../assets/images/bison-natural-authentik-design-559030.jpg';
import leather from '../assets/images/Natural-Blond-Tipping-560598-leather.jpeg';
import homePhilosophy from '../assets/images/bison-philosophy-559032-home-philosophy.jpeg';

import gal1 from '../assets/gallery/Bison-Natural-Authentik-Gallery-20-562960.jpg';
import gal2 from '../assets/gallery/Bison-Natural-Authentik-Gallery-10-559039.jpg';
import gal3 from '../assets/gallery/Bison-Natural-Authentik-Gallery-14-559035.jpg';
import gal4 from '../assets/gallery/Bison-Natural-Authentik-Gallery-9-559040.jpg';
import gal5 from '../assets/gallery/Bison-Natural-Authentik-Gallery-17-562961.jpg';
import gal6 from '../assets/gallery/Bison-Natural-Authentik-Gallery-18-561788.jpg';

import logo from '../assets/logo/bison-natural-logo2-561791.png';

import TextLayout from '../UI/TextLayout';

import styles from './pages-css/home.module.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Home() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Home</title>
        <meta
          name="description"
          content="Bison Natural Authentic Design - Home"
        ></meta>
        <link rel="canonical" href="/home" />
      </Helmet>
      <Container fluid className={styles['carousel-container']}>
        <img src={logo} alt="logo" />
        <Carousel className={styles.carousel}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              pause="hover"
              src={img1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              pause="hover"
              src={img2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              pause="hover"
              src={img3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              pause="hover"
              src={img4}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </Container>
      <Container fluid className={`${styles['bison-heritage-container']} p-5`}>
        <Row className={styles.row}>
          <Col sm={12} md={6}>
            <img src={bison} alt="bison" className="w-50" />
            <TextLayout
              title="BISON HERITAGE"
              description={
                <>
                  <p>
                    BISON was chosen as our proud logo and a symbol of the
                    revival of a nearly extinct, truly amazing, natural
                    resource. For hundreds of years this massive animal was the
                    sole source of survival for many people and it is now
                    experiencing a huge recovery.
                  </p>
                  <p>
                    Today we promote using the latest technology married with
                    only the best natural raw materials, all of which have
                    enriched human lives without any impact on The Environment.
                    Nature is our most precious resource and we encourage
                    everyone to join with us in recognition of using the
                    renewable product.
                  </p>
                </>
              }
            />
          </Col>
          <Col sm={12} md={6} className={styles['bison-heritage-image']}>
            <Container>
              <img src={img5} alt="design" className="w-100" />
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid className={`${styles['accordion-container']} py-5`}>
        <TextLayout
          title="BISON NATURAL AUTHENTIC"
          className="py-3"
        ></TextLayout>
        <Row className="px-5">
          <Col sm={6} lg={3} className="mb-3">
            <Container>
              <Container className={styles['accordion-image-container']}>
                <img src={design} alt="demo" className="w-100 pb-3" />
              </Container>

              <Accordion className={styles.accordion}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={styles['accordion-header']}>
                    <h1>DESIGN</h1>
                  </Accordion.Header>
                  <Accordion.Body className={styles['accordion-body']}>
                    <>
                      <p>
                        Bison impresses by its solid design and perfect comfort.
                        A rational minimalistic form language and functional
                        added value merge into harmonic objects. By omitting all
                        superfluous decorative elements, Bison product are
                        reduced to the essence.
                      </p>
                      <p>
                        A neutral style, which can be merged with any
                        architecture or surrounding space.
                      </p>
                      <p>
                        Bison represents the ultimate and uncompromising
                        realization of a material philosophy, that result in
                        some of the highest value added and natural products in
                        the market.
                      </p>
                      <h3>"Aesthetic Functionality"</h3>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </Col>
          <Col sm={6} lg={3} className="mb-3">
            <Container>
              <Container className={styles['accordion-image-container']}>
                <img src={comfort} alt="demo" className="w-100 pb-3" />
              </Container>
              <Accordion className={styles.accordion}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={styles['accordion-header']}>
                    <h1>COMFORT</h1>
                  </Accordion.Header>
                  <Accordion.Body className={styles['accordion-body']}>
                    <>
                      <p>
                        Furniture should merge harmonic into our living space
                        and should be functional and comfortable to use. Bison
                        products are developed in particular with focus on
                        amenity and extraordinary comfort.
                      </p>
                      <p>
                        We are convinced, that it is possible to create modern,
                        stylish objects, which are far superior to synthetic
                        products in terms of durability, sensibility and
                        emotion.
                      </p>
                      <p>
                        The organic tanning of our leather represents the utmost
                        refinement of leather and lend our products an unequaled
                        emotional character.
                      </p>
                      <h3>"UNCOMPROMISING GRACE"</h3>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </Col>
          <Col sm={6} lg={3} className="mb-3">
            <Container>
              <Container className={styles['accordion-image-container']}>
                <img src={leather} alt="demo" className="w-100 pb-3" />
              </Container>
              <Accordion className={styles.accordion}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={styles['accordion-header']}>
                    <h1> BISON LEATHER</h1>
                  </Accordion.Header>
                  <Accordion.Body className={styles['accordion-body']}>
                    <>
                      <p>
                        Bison leather hides are always full grain leathers left
                        in its natural state, exhibit all natural markings and
                        impurities and reflect the essence of leather in its
                        original and unaltered state.
                      </p>
                      <p>
                        Bison leather hides are strictly processed to our
                        specifications at selected tanneries and exclusively
                        tanned with eco-tested dyes.
                      </p>
                      <p>
                        Each Bison product is unique, because each hide is
                        different with its own characteristics, marks and
                        history. Each part of the sofa is hand selected with
                        care according to its position and visibility on the
                        sofa and tailored to a harmonic whole.
                      </p>
                      <p>
                        The animal receives a second life and fills us with
                        respect to nature - the most valuable recource we still
                        have today. Instictively we sense the strength of the
                        leather and at the same time the softness and
                        sensibility and assume an emotional connection to the
                        history of the donor.
                      </p>
                      <h3>"Aesthetic Functionality"</h3>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </Col>
          <Col sm={6} lg={3} className="mb-3">
            <Container>
              <Container className={styles['accordion-image-container']}>
                <img src={homePhilosophy} alt="demo" className="w-100 pb-3" />
              </Container>
              <Accordion className={styles.accordion}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={styles['accordion-header']}>
                    <h1>BISON PHILOSOPHY</h1>
                  </Accordion.Header>
                  <Accordion.Body className={styles['accordion-body']}>
                    <>
                      <p>
                        Sustainability is not only trend, but consciousness
                        about the environment, nature and its resources. Bison
                        products are as far as possible made from natural
                        available raw material and thus fullfill the claim of
                        sustainability.
                      </p>
                      <p>
                        All Bison products are re-usable, recycable and can be
                        returned to nature, without disturbance to the
                        environment(cradle to cradle).
                      </p>

                      <h3>"We borrow from nature ... and return after use"</h3>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid className={styles['gallery-container']}>
        <Row>
          <Col xs={4} sm={4} lg={4}>
            <img src={gal1} alt="gal-1" />
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <img src={gal2} alt="gal-1" />
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <img src={gal3} alt="gal-1" />
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <img src={gal4} alt="gal-1" />
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <img src={gal5} alt="gal-1" />
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <img src={gal6} alt="gal-1" />
          </Col>
        </Row>

        <Link to={'/gallery'} className={styles['gallery-text']}>
          <p>view gallery</p>
        </Link>
      </Container>
    </>
  );
}

export default Home;
