import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './components-css/footer.module.css';

import logo from '../assets/logo/bison-natural-logo2-561791.png';
import bisonBrochure from '../assets/bison-downloadble-files/Bison-Brochure-2021-563347.pdf';
import bisonImpression from '../assets/bison-downloadble-files/BM-Impressions-2021-563221.pdf';

function Footer() {
  return (
    <>
      <Container fluid className={`${styles.footer} px-5`}>
        <Row>
          <Col sm={12} md={3} className="my-2">
            <img src={logo} alt="logo" />
          </Col>
          <Col sm={12} md={3} className="my-2">
            <h1>contact us</h1>
            <h2>Matthew Slater</h2>
            <ul>
              <li>
                <i className="fa-solid fa-phone"></i>
                <a href="tel:+66866881035">+66 86 688 1035</a>
              </li>
              <li>
                <i className="fa-regular fa-envelope"></i>
                <a href="mailto:matt@theworks.co.th">matt@asalon.co.th</a>
              </li>
            </ul>
            <br />
            <h2>Klaus Kummer</h2>
            <ul>
              <li>
                <i className="fa-solid fa-phone"></i>
                <a href="tel:+66818261624">+66 81 826 1624</a>
              </li>
              <li>
                <i className="fa-regular fa-envelope"></i>
                <a href="mailto:matt@theworks.co.th">klausk@kdt-inter.com</a>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={3} className="my-2">
            <h1>BISON HOME</h1>
            <h2>ASALON CO., LTD.</h2>
            <p>
              499/24 Moo 13, Soi Kingkaew 25/1 Kingkaew Road T. Rachathewa,
              Bangplee Samutprakan 10540 Thailand
            </p>
            <br />
            <h1>Showroom Locations</h1>
            <p>
              147 Soi Sukhumvit 63, Khlong Tan Nuea, Watthana, Bangkok 10110
            </p>
            <a
              href="https://goo.gl/maps/WE96diYuaz8YoHsH6"
              target="_blank"
              rel="noreferrer"
            >
              View on map
            </a>
            <Container className="p-0 mt-3">
              <iframe
                title="embedded-google-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.777652821488!2d100.5841253!3d13.731907200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29fad2e776ed1%3A0x6d2f52ab96edf0c2!2sBurgundy%20Place%20Thonglor!5e0!3m2!1sen!2s!4v1685690131890!5m2!1sen!2s"
                width="auto"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Container>
          </Col>
          <Col sm={12} md={3} className="my-2">
            <h1>Download our brochure (PDF)</h1>
            <ul>
              <li>
                <a
                  href={bisonBrochure}
                  download="Bison-Natural-Authentic-Brochure"
                >
                  Bison Brochure
                </a>
              </li>
              <li>
                <a
                  href={bisonImpression}
                  download="Bison-Natural-Authentic-Impressions"
                >
                  Bison Impressions
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
