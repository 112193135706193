import UIButton from './UIButton';
import styles from './UI-css/BackToTopButton.module.css';
import { useEffect, useRef } from 'react';
// import { useState } from 'react';

function BackToTopButton() {
  let backToTopBtn = useRef();
  let scrollHeight = useRef();
  let scrollDistant = useRef();

  function getScrollDistant() {
    scrollHeight.current = document.documentElement.scrollHeight;
    scrollDistant.current = document.documentElement.scrollTop;
  }

  function backToTopBtnHandler() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    function showBackToTopBtnHandler() {
      const backToTopBtnCurrent = (backToTopBtn.current =
        document.getElementById('backToTopBtn'));
      let calcDistantScrollHeight = Math.round(scrollHeight.current * 0.2);

      if (
        scrollDistant.current === undefined ||
        scrollDistant.current < calcDistantScrollHeight
      ) {
        !backToTopBtnCurrent.hasAttribute('hidden') &&
          backToTopBtnCurrent.setAttribute('hidden', 'true');
      }
      if (scrollDistant.current > calcDistantScrollHeight) {
        backToTopBtnCurrent.removeAttribute('hidden');
      }
      backToTopBtnCurrent.addEventListener('click', backToTopBtnHandler);
    }
    showBackToTopBtnHandler();
    document.addEventListener('scroll', () => {
      getScrollDistant();
      showBackToTopBtnHandler();
    });
  }, []);

  return (
    <>
      <UIButton className={styles['back-to-top-btn']} id="backToTopBtn">
        <i className="fa-solid fa-arrow-up"></i>
      </UIButton>
    </>
  );
}

export default BackToTopButton;
