import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextLayout from '../UI/TextLayout';

import styles from './pages-css/leather.module.css';

import blackNubuck from '../assets/images/leather/Charcoal-Black-Nubuk-560601.jpg';
import blackTan from '../assets/images/leather/Charcoal-Black-Tan-560600.jpg';
import blondTan from '../assets/images/leather/Natural-Blond-Tan-560599.jpg';
import blondTipping from '../assets/images/leather/Natural-Blond-Tipping-560598.jpg';
import darkBrownNubuck from '../assets/images/leather/Natural-Dark-Brown-Nubuk-560597.jpg';
import darkBrownTan from '../assets/images/leather/Natural-Dark-Brown-Tan-560596.jpg';

import { Helmet } from 'react-helmet-async';

function Leather() {
  return (
    <>
      <Helmet>
        <title> Bison Natural Authentic - Leather</title>
        <meta
          name="description"
          content="Bison Natural Authentic Design - Leather"
        ></meta>
        <link rel="canonical" href="/leather" />
      </Helmet>
      <Container className={styles['leather-text-container']}>
        <TextLayout
          title="BISON LEATHER"
          description={
            <>
              <p>
                Bison leather hides are always full grain leathers left in its
                natural state, exhibit all natural markings and impurities and
                reflect the essence of leather in its original and unaltered
                state. Bison leather hides are always full grain leathers left
                in its natural state, exhibit all natural markings and
                impurities and reflect the essence of leather in its original
                and unaltered state.
              </p>
              <p>
                Bison leather hides are strictly processed to our specifications
                at selected tanneries and exclusively tanned with eco-tested
                dyes.
              </p>
              <p>
                Each Bison product is unique, because each hide is different
                with its own characteristics, marks and history. Each part of
                the sofa is hand selected with care according to its position
                and visibility on the sofa and tailored to a harmonic whole.
              </p>
              <p>
                The animal receives a second life and fills us with respect to
                nature - the most valuable recource we still have today.
                Instictively we sense the strength of the leather and at the
                same time the softness and sensibility and assume an emotional
                connection to the history of the donor.
              </p>
            </>
          }
          concluding={`"We borrow from nature ... and return after use"`}
        ></TextLayout>
      </Container>
      <Container className={`${styles['leather-image-container']} mb-5`}>
        <Row>
          <Col xs={6} md={4}>
            <img
              src={blondTipping}
              className="w-100"
              alt="Natural Blond Tipping"
            />
            <p>Natural Blond Tipping</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={blondTan} className="w-100" alt="Natural Blond Tan" />
            <p>Natural Blond Tan</p>
          </Col>
          <Col xs={6} md={4}>
            <img
              src={darkBrownTan}
              className="w-100"
              alt="Natural Dark Brown Tan"
            />
            <p>Natural Dark Brown Tan</p>
          </Col>
          <Col xs={6} md={4}>
            <img
              src={darkBrownNubuck}
              className="w-100"
              alt="Natural Dark Brown Nubuk"
            />
            <p>Natural Dark Brown Nubuk</p>
          </Col>
          <Col xs={6} md={4}>
            <img src={blackTan} className="w-100" alt="Charcoal Black Tan" />
            <p>Charcoal Black Tan</p>
          </Col>
          <Col xs={6} md={4}>
            <img
              src={blackNubuck}
              className="w-100"
              alt="Charcoal Black Nubuk"
            />
            <p>Charcoal Black Nubuk</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Leather;
